import logo from './assets/the-hand.svg';
import './App.scss';

function App() {
  return (
    <div class="container-fluid main-wrapper">
      <img
        src={logo}
        alt="makemagic.com | modern websites for modern people."
      />
      <div class="title-con">
        <h1>makemagic.com</h1>
        <h2>2023</h2>
      </div>
    </div>
  );
}

export default App;
